<template>
  <div class="album">
    <EditAlbum :currentAlbum="currentAlbum" />
  </div>
</template>

<script>

import EditAlbum from './edit/Album'

import { mapState, mapActions } from 'vuex'

export default {

  name: 'Edit',

  props: ['slug'],

  components: {
    EditAlbum
  },

  computed: mapState(['currentAlbum']),

  methods: mapActions(['setAlbum']),

  updated () {
    if (this.slug) this.setAlbum(this.slug)
  },

  mounted () {
    if (this.slug) this.setAlbum(this.slug)
  }
}
</script>

<style lang="postcss" scoped>
  .album {
    @apply h-full overflow-hidden
  }
</style>
